<template>
  <div class="page-brand-orders">
    <v-card
      flat
      class="px-8"
      v-if="orders"
    >
      <v-card-title>
        <DataTableFilter
          class="col-11 col-lg-8 pl-0"
          width-of-filters-titles="3"
          ref="filter"
          @update="updateFilter"
          :filters="filterObject"
        ></DataTableFilter>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="fas fa-search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="orders"
          :search="search"
          :custom-filter="customFilter"
          sort-by="created"
          :sort-desc="true"
          :loading="loadingTable"
          :footer-props="{
            itemsPerPageOptions: [15, 50, 100, -1]
          }"
          class="cursorTable"
        >
          <template v-slot:[`item.status`]="{ item }">
            <router-link
              :to="{name: 'Order', params: { orderUUID: `${item._id}`}}"
              class="text-decoration-none"
              :title="item.status"
            >
              <template v-if="item.status === 'NEW'">
                <v-icon
                  small
                  color="info"
                  class="mr-2"
                >
                  fas fa-credit-card
                </v-icon>
                <v-icon
                  v-if="item.paymentStatus === 'PAID'"
                  small
                  color="success"
                  class="mr-2"
                >
                  fas fa-check-circle
                </v-icon>
                {{ $t(item.paymentStatus) }}
              </template>
              <template v-else-if="item.status === 'SUBMITTED'">
                <v-icon
                  small
                  color="warning"
                  class="mr-2"
                >
                  far fa-calendar-alt
                </v-icon>
                {{ $t(item.status) }}
              </template>
              <template v-else-if="item.status === 'INPROGRESS'">
                <v-icon
                  small
                  color="primary"
                  class="mr-2"
                >
                  fas fa-spinner
                </v-icon>
                {{ $t(item.status) }}
              </template>
              <template v-else-if="item.status === 'DELIVERED'">
                <v-icon
                  small
                  color="success"
                  class="mr-2"
                >
                  fas fa-check
                </v-icon>
                {{ $t(item.status) }}
              </template>
              <template v-else-if="item.status === 'CANCELED'">
                <v-icon
                  small
                  color="error"
                  class="mr-2"
                >
                  fas fa-ban
                </v-icon>
                {{ $t(item.status) }}
              </template>
            </router-link>
          </template>
          <template v-slot:[`item.paymentStatus`]>
          </template>
          <template v-slot:[`item.created`]="{ item }">
            <router-link
              :to="{name: 'Order', params: { orderUUID: `${item._id}`}}"
              class="text-decoration-none"
            >
              {{ $d(new Date(item.created), "long", $userLocale) }}
            </router-link>
          </template>
          <template v-slot:[`item.invoiceNumber`]="{ item }">
            <router-link
              :to="{name: 'Order', params: { orderUUID: `${item._id}`}}"
              class="text-decoration-none"
            >
              n°{{ item.invoiceNumber }}
            </router-link>
          </template>
          <template v-slot:[`item.quote.selling.totalAmount`]="{ item }">
            <router-link
              :to="{name: 'Order', params: { orderUUID: `${item._id}`}}"
              class="text-decoration-none"
            >
              {{ $n(item.quote.selling.totalAmount, 'currency', 'fr') }}
            </router-link>
          </template>
          <template v-slot:[`item.quote.purchaseOrderNumber`]="{ item }">
            <template
              v-if="item.quote.purchaseOrderNumber"
            >
              <router-link
                :to="{name: 'Order', params: { orderUUID: `${item._id}`}}"
                class="text-decoration-none"
              >
                {{ item.quote.purchaseOrderNumber }}
              </router-link>
            </template>
          </template>
          <template v-slot:[`item.user`]="{ item }">
            <router-link
              :to="{name: 'Order', params: { orderUUID: `${item._id}`}}"
              class="text-decoration-none"
            >
              {{ item.user.firstname }} {{ item.user.lastname }}
            </router-link>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              :to="{name: 'Order', params: { orderUUID: `${item._id}`}}"
              class="primary"
              :title="$t('view')"
            >
              <v-icon small class="far fa-eye"></v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ApiErrorParser, DataTableFilter, CustomFilterForSearchDatatable } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageBrandOrders.i18n.json');

export default {
  name: 'PageBrandOrders',
  components: { DataTableFilter },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      headers: [],
      search: '',
      orders: [],
      loadingTable: false,
      statusFilter: [],
      paymentFilter: [],
      filterObject: {
        status: [
          {
            name: this.$t('NEWORDER'),
            value: 'NEW', 
            img: 'new.png',
            number: 0,
            get selected() {
              return this.number > 0;
            }
          },
          {
            name: this.$t('INPROGRESS'),
            value: 'INPROGRESS', 
            iconColor: 'primary',
            icon: 'fas fa-spinner',
            number: 0,
            get selected() {
              return this.number > 0;
            }
          },
          {
            name: this.$t('DELIVERED'),
            value: 'DELIVERED', 
            iconColor: 'success',
            icon: 'fas fa-check',
            number: 0,
          },
          {
            name: this.$t('CANCELED'),
            value: 'CANCELED', 
            iconColor: 'error',
            icon: 'fas fa-ban',
            number: 0,
          }
        ],
        paymentStatus: [
          {
            name: this.$t('WAITING'),
            value: 'WAITING', 
            img: 'creditCard.svg',
            number: 0,
          },
          {
            name: this.$t('PAID'),
            value: 'PAID', 
            img: 'creditCardCheck.svg',
            number: 0,
          },
          {
            name: this.$t('ERROR'),
            value: 'ERROR', 
            img: 'creditCardTimes.svg',
            number: 0,
          },
        ]
      },
    };
  },
  created() {
    /**
     * GET BRAND
     */
    this.getBrandOrders();
  },
  mounted() {
    this.headers = [
      {
        text: this.$t('status'),
        value: 'status',
        filter: (value) => {
          if (this.statusFilter.length === 0) return true;
          if (this.statusFilter.includes(value)) return true;
        }
      },
      {
        text: '',
        sortable: false,
        value: 'paymentStatus',
        filter: (value) => {
          if (this.paymentFilter.length === 0) return true;
          if (this.paymentFilter.includes(value)) {
            return true;
          }
        },
      },
      {
        text: this.$t('invoiceID'),
        value: 'invoiceNumber',
      },
      {
        text: this.$t('purchaseOrder'),
        value: 'quote.purchaseOrderNumber',
        sort: this.sortPurchaseOrderFn
      },
      {
        text: this.$t('price'),
        value: 'quote.selling.totalAmount',
      },
      {
        text: this.$t('User'),
        value: 'user',
        sort: this.sortUserFn
      },
      { text: this.$t('created'), value: 'created' },
      {
        text: this.$t('Action'),
        value: 'action',
        align: 'center',
        sortable: false,
      },
    ];
  },
  methods: {
    sortPurchaseOrderFn(cf1, cf2) {
      const po1 = cf1?.[0]?.StringValue;
      const po2 = cf2?.[0]?.StringValue;
      if(po1 === po2) {
        return 0;
      }
      return po1 < po2 ? 1 : -1;
    },
    sortUserFn(u1, u2) {
      const fullName1 = u1.lastname + '.' + u1.firstname;
      const fullName2 = u2.lastname + '.' + u2.firstname;
      if(fullName1 === fullName2) {
        return 0;
      }
      return fullName1 < fullName2 ? 1 : -1;
    },
    /**
     * GET BRAND
     */
    getBrandOrders() {
      this.loadingTable = true;
      this.loading = true;
      this.$apiInstance
        .getBrandOrders(this.$route.params.brandUUID)
        .then(
          (brandOrdersData) => {
            this.orders = brandOrdersData;
            this.orders.forEach(order => {
              this.filterObject.status.find(x => x.value === order.status).number++;
              const paymentStatusObject = this.filterObject.paymentStatus.find(x => x.value === order.paymentStatus && order.status !== 'CANCELED');
              if(paymentStatusObject) {
                paymentStatusObject.number++;
              }
            });
          },
          (error) => {
            /**
             * ERROR GET BRAND
             */
            this.brandErrorMessage = ApiErrorParser.parse(error);
          }
        )
        .finally(() => {
          this.loadingTable = false;
        });
    },
    customFilter(value, search, item) {
      if (!search) return true;
      search = search.toString().toLowerCase();
      if (item.invoiceNumber.includes(search)) return true;
      if (item.quote.purchaseOrderNumber.toLowerCase().includes(search)) return true;
      if (item.brand.name.toLowerCase().includes(search)) return true;
      return CustomFilterForSearchDatatable.customFilter(value, search, this);
    },
    updateFilter(updatedFilter) {
      this.statusFilter = updatedFilter.status;
      this.paymentFilter = updatedFilter.paymentStatus;
      if (this.paymentFilter.length > 0 && !this.statusFilter.includes('NEW') && !this.paymentFilter.includes('PAID')) {
        this.statusFilter.push('NEW');
      }
      
      this.filterObject.status.forEach(status => {
        if(this.paymentFilter.length > 0 && !this.paymentFilter.includes('PAID') && status.value !== 'NEW' ) {
          status.disabled = true;
          this.statusFilter.length = 0;
          this.statusFilter.push('NEW');
        } else {
          status.disabled = false;
        }
      });
    },
    goToPageItem(item) {
      this.$router.push({name: 'Order', params: { orderUUID: `${item._id}`}});
    },
  },
};
</script>

<style scoped>
  tbody td {
    vertical-align: middle;
  }
  .cursorTable {
    cursor: pointer;
  }
</style>