<template>
  <div class="page-brand-quotes">
    <v-card
      flat
      class="px-8"
      v-if="quotes"
    >
      <v-card-title>
        <DataTableFilter
          class="col-11 col-lg-8 pl-0"
          width-of-filters-titles="1"
          ref="filter"
          @update="updateFilter"
          :filters="filterObject"
        ></DataTableFilter>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="fas fa-search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="quotes"
          :search="search"
          :custom-filter="customFilter"
          sort-by="created"
          :sort-desc="true"
          :loading="loadingTable"
          :footer-props="{
            itemsPerPageOptions: [15, 50, 100, -1]
          }"
          class="cursorTable"
          @click:row="goToPageItem($event)"
        >
          <template v-slot:[`item.status`]="{ item }">
            <router-link
              :to="{name: 'Quote', params: { quoteUUID: `${item._id}`}}"
              class="text-decoration-none"
              :title="item.status"
            >
              <template v-if="item.status === 'LOCKED'">
                <v-icon small color="info">
                  fas fa-unlock
                </v-icon>
              </template>
              <template v-else-if="item.status === 'READY'">
                <v-icon small color="warning">
                  fas fa-clock
                </v-icon>
              </template>
              <template v-else-if="item.status === 'DECLINED'">
                <v-icon small color="error">
                  fas fa-times-circle
                </v-icon>
              </template>
              <template v-else-if="item.status === 'ACCEPTED' || item.status === 'CLOSED'">
                <v-icon small color="success">
                  fas fa-check-circle
                </v-icon>
              </template>
            </router-link>
          </template>
          <template v-slot:[`item.selling.totalAmount`]="{ item }">
            <router-link
              :to="{name: 'Quote', params: { quoteUUID: `${item._id}`}}"
              class="text-decoration-none"
            >
              <div class="text-right">
                <span v-if="item.selling">
                  {{ $n(item.selling.totalAmount, "currency", 'fr') }}
                </span>
                <span v-else class="text-caption">
                  --,-- €
                </span>
              </div>
            </router-link>
          </template>
          <template v-slot:[`item.user`]="{ item }">
            <router-link
              :to="{name: 'Quote', params: { quoteUUID: `${item._id}`}}"
              class="text-decoration-none"
            >
              {{ item.user.firstname }} {{ item.user.lastname }}
            </router-link>
          </template>
          <template v-slot:[`item.created`]="{ item }">
            <router-link
              :to="{name: 'Quote', params: { quoteUUID: `${item._id}`}}"
              class="text-decoration-none"
            >
              {{ $d(new Date(item.created), "long", $userLocale) }}
            </router-link>
          </template>
          <template v-slot:[`item.quoteNumber`]="{ item }">
            <router-link
              :to="{name: 'Quote', params: { quoteUUID: `${item._id}`}}"
              class="text-decoration-none"
            >
              n°{{ item.quoteNumber }}
            </router-link>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              :to="{name: 'Quote', params: { quoteUUID: `${item._id}`}}"
              class="primary"
              :title="$t('view')"
            >
              <v-icon small>
                far fa-eye
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ApiErrorParser, DataTableFilter, CustomFilterForSearchDatatable } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageBrandQuotes.i18n.json');

export default {
  name: 'PageBrandQuotes',
  components: { DataTableFilter },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      headers: [],
      quotes: [],
      search: '',
      loadingTable: false,
      statusFilter: [],
      filterObject: {
        status: [
          {
            name: this.$t('QuoteLocked'),
            value: 'LOCKED', 
            iconColor: 'info',
            icon: 'fas fa-unlock',
            number: 0,
            get selected() {
              return this.number > 0;
            }
          },
          {
            name: this.$t('QuoteReady'),
            value: 'READY', 
            iconColor: 'warning',
            icon: 'fas fa-clock',
            number: 0,
            get selected() {
              return this.number > 0;
            }
          },
          {
            name: this.$t('QuoteDeclined'),
            value: 'DECLINED', 
            iconColor: 'error',
            icon: 'fas fa-times-circle',
            number: 0,
          },
          {
            name: this.$t('QuoteAccepted'),
            value: 'ACCEPTED', 
            iconColor: 'success',
            icon: 'fas fa-check-circle',
            number: 0,
          }
        ]
      },
    };
  },
  created() {
    /**
     * GET BRAND
     */
    this.getBrandQuotes();
  },
  mounted() {
    this.headers = [
      {
        text: this.$t('Status'),
        value: 'status',
        filter: (value) => {
          if (this.statusFilter.length === 0) return true;
          if (this.statusFilter.includes(value) || (value === 'CLOSED' && this.statusFilter.includes('ACCEPTED'))) return true;
        }
      },
      {
        text: this.$t('QuoteID'),
        value: 'quoteNumber',
      },
      {
        text: this.$t('priceVAT'),
        value: 'selling.totalAmount',
        align: 'right',
      },
      {
        text: this.$t('User'),
        value: 'user',
        sort: this.sortUserFn
      },
      { text: this.$t('Created'), value: 'created' },
      {
        text: this.$t('Action'),
        value: 'action',
        align: 'center',
        sortable: false,
      },
    ];
  },
  methods: {
    sortUserFn(u1, u2) {
      const fullName1 = u1.lastname + '.' + u1.firstname;
      const fullName2 = u2.lastname + '.' + u2.firstname;
      if(fullName1 === fullName2) {
        return 0;
      }
      return fullName1 < fullName2 ? 1 : -1;
    },
    /**
     * GET BRAND
     */
    getBrandQuotes() {
      this.loadingTable = true;
      this.loading = true;
      this.$apiInstance
        .getBrandQuotes(this.$route.params.brandUUID)
        .then(
          (brandQuotesData) => {
            this.quotes = brandQuotesData;
            this.quotes.forEach(quote => {
              this.filterObject.status.find(x => x.value === quote.status || (x.value === 'ACCEPTED' && quote.status === 'CLOSED')).number++;
            });
          },
          (error) => {
            /**
             * ERROR GET BRAND
             */
            this.brandErrorMessage = ApiErrorParser.parse(error);
          }
        )
        .finally(() => {
          this.loadingTable = false;
        });
    },
    customFilter(value, search, item) {
      if (!search) return true;
      search = search.toString().toLowerCase();
      if (item.quoteNumber.includes(search)) return true;
      if (item.brand.name.toLowerCase().includes(search)) return true;
      return CustomFilterForSearchDatatable.customFilter(value, search, this);
    },
    updateFilter(updatedFilter) {
      this.statusFilter = updatedFilter.status;
    },
    goToPageItem(item) {
      this.$router.push({name: 'Quote', params: { quoteUUID: `${item._id}`}});
    },
  },
};
</script>

<style scoped>
  tbody td {
    vertical-align: middle;
  }
  .cursorTable {
    cursor: pointer;
  }
</style>