var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.partsErrorMessage ? _c('v-card', {
    staticClass: "px-3 mx-sm-5 mt-10",
    attrs: {
      "flat": ""
    }
  }, [_c('v-alert', {
    staticClass: "mt-4 col-12 text-center",
    attrs: {
      "dismissible": "",
      "text": "",
      "outlined": "",
      "type": "error"
    }
  }, [_vm._v(" " + _vm._s(_vm.partsErrorMessage) + " ")])], 1) : _vm._e(), _vm.brand && (!_vm.parts || _vm.parts.length > 0) ? _c('DataTableParts', {
    attrs: {
      "parts": _vm.parts,
      "brand": _vm.brand,
      "show-button-to-enable-tags": false,
      "show-basket-button": false,
      "formating-url-for": 'admin',
      "parts-image-data-u-r-l": _vm.partsImageDataURL
    },
    on: {
      "goToPageItem": function ($event) {
        return _vm.goToPageItem($event);
      },
      "getPartImage": _vm.getBrandPartImage
    }
  }) : _vm._e(), _vm.parts && _vm.parts.length === 0 ? _c('div', {
    staticClass: "text-center text-info",
    attrs: {
      "id": "warehouse-empty"
    }
  }, [_c('p', {
    staticClass: "display-3"
  }, [_vm._v(" " + _vm._s(_vm.$t('WarehouseEmpty')) + " ")]), _c('span', {
    staticClass: "fas fa-cubes"
  })]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }