<template>
  <div>
    <v-card
      flat
      class="px-3 mx-sm-5 mt-10"
      v-if="partsErrorMessage"
    >
      <v-alert
        dismissible
        text
        outlined
        type="error"
        
        class="mt-4 col-12 text-center"
      >
        {{ partsErrorMessage }}
      </v-alert>
    </v-card>
    <DataTableParts
      v-if="brand && (!parts || parts.length > 0)"
      :parts="parts"
      :brand="brand"
      :show-button-to-enable-tags="false"
      :show-basket-button="false"
      :formating-url-for="'admin'"
      :parts-image-data-u-r-l="partsImageDataURL"
      @goToPageItem="goToPageItem($event)"
      @getPartImage="getBrandPartImage"
    ></DataTableParts>
    <!-- DISPLAY IF WAREHOUSE IS EMPTY -->
    <!-- DISPLAY IF WAREHOUSE IS EMPTY -->
    <!-- DISPLAY IF WAREHOUSE IS EMPTY -->
    <div
      v-if="parts && parts.length === 0"
      id="warehouse-empty"
      class="text-center text-info"
    >
      <p class="display-3">
        {{ $t('WarehouseEmpty') }}
      </p>
      <span class="fas fa-cubes"></span>
    </div>
  </div>
</template>

<script>
import { ApiErrorParser, DataTableParts, DownloadBrandPartImage } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageBrandParts.i18n.json');

export default {
  name: 'PageBrandParts',
  components: { DataTableParts },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      brand: null,
      parts: null,
      loadingTable: false,
      partsErrorMessage: null,
      partsImageDataURL: {}
    };
  },
  created() {
    this.getBrand();
    /**
     * GET BRAND PARTS
     */
    this.getBrandParts();
  },
  methods: {
    getBrandPartImage(part) {
      if(part.image) {
        const [promise, req] = DownloadBrandPartImage.downloadBrandPartImage(
          this.$apiInstance,
          this.$route.params.brandUUID, 
          part._id,
          new Date(part.image.created).getTime()
        );

        promise.then(data => {
          const png = new File([new Uint8Array(data.buffer)], 'part.png', {
            type: 'image/png'
          });
          this.partsImageDataURL[part._id] = URL.createObjectURL(png);
        });
      }
    },
    getBrand() {
      this.$apiInstance.getAdminBrand(this.$route.params.brandUUID).then(data => {
        this.brand = data;
      }) ;
    },
    /**
     * GET BRAND PARTS
     */
    getBrandParts() {
      this.loadingTable = true;
      /**
       * GET BRAND PARTS
       */
      this.$apiInstance
        .getBrandParts(this.$route.params.brandUUID)
        .then(
          (data) => {
            for(const part of data) {
              this.$set(this.partsImageDataURL, part._id, null);
            }
            this.parts = data;
          },
          (error) => {
            /**
             * ERROR GET BRAND PARTS
             */
            this.partsErrorMessage = ApiErrorParser.parse(error);
          }
        )
        .finally(() => {
          this.loadingTable = false;
        });
    },
    goToPageItem(itemId) {
      this.$router.push({name: 'Part', params: { brandUUID: `${this.$route.params.brandUUID}`, partUUID: `${itemId}`}});
    },
  },
};
</script>
